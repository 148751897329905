import Image from 'next/image'
import React from 'react'

type Props = {
    lang: any
}

const Your360Dream = (props: Props) => {
    return (
        <div className='container mt-2 mb-6 lg:h-400px h-60vh relative overflow-hidden'>
            <div className={`w-full h-full absolute bg-center bg-cover bg-no-repeat p-2`}>
                <Image src={"/static/images/explore360_banner-min.png"} alt="Explore 360" layout='fill' sizes='100vw' objectFit='cover' />
            </div>
            <div className='h-full bg-gradient-to-t lg:bg-gradient-to-r from-black-cc to-transparent absolute p-8 lg:p-16 flex lg:items-center items-end'>
                <div className='text-white w-full lg:w-1/3 flex flex-col items-center lg:items-start'>
                    <p className='text-lg lg:text-2xl font-semibold mb-4'>{props.lang.tag_title}</p>
                    <p className='text-xs lg:text-base mb-4 text-center lg:text-left'>{props.lang.tag_text}</p>
                    <a href='/search?has_360=1' className='px-5 py-2 text-sm lg:text-base rounded-sm hover:bg-main-dark cursor-pointer bg-main'>Explore More</a>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Your360Dream) 